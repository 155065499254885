import { Swipe } from "./swipe.js";

let Carousel = function (element, options) {
  if (!element) {
    return false;
  }
  let defaults = {
    desktopSwipe: false,
    eventStop: false,
    infinite: false,
    clones: 0,
    timer: false,
    index: 1,
    thumbs: false,
    init: function () {},
    swipeStart: false,
    swipeEnd: false,
    click: function () {},
    active: function () {},
  };
  let settings = Object.assign({}, defaults, options);
  let carousel = {
    element: element,
    clip: element.querySelector(".carousel-clip"),
    stripe: element.querySelector(".carousel-stripe"),
    items: element.querySelectorAll(".carousel-item"),
    activeIndex: 0,
    active: false,
    controls: {
      prev: element.querySelector(".carousel-prev"),
      next: element.querySelector(".carousel-next"),
      dots: element.querySelector(".carousel-dots"),
    },
    init: function () {
      settings.clones = Math.min(settings.clones, carousel.items.length);
      carousel.transition = carousel.stripe.style.transition;
      if (carousel.items.length > 1) {
        carousel.createDots(settings.thumbs);
        if (settings.infinite) {
          if (settings.clones === 0) {
            settings.clones = 1;
          }
          carousel.cloning();
        }

        carousel.setActive(settings.clones + settings.index - 1, false);

        Swipe(carousel.clip, carousel.stripe, {
          prev: function () {
            //console.log("prev");
            carousel.changePage(-1);
            //console.log(arguments);
          },
          next: function () {
            //console.log("next");
            carousel.changePage(1);
            //console.log(arguments);
          },
          desktopSwipe: settings.desktopSwipe,
          swipePercent: 25,
          eventStop: settings.eventStop,
        });
      }
      carousel.setWidth();
      if (settings.timer && carousel.items.length > 1) {
        carousel.timer = settings.timer;
        carousel.setTimer(carousel.timer);
      }
      settings.init(carousel);
      if (typeof settings.swipeEnd === "function") {
        settings.swipeEnd(carousel.activeIndex, 0, carousel);
      }
    },
    cloning: function () {
      let clone = null;
      let j = 0;
      for (let i = settings.clones; i > 0; i--) {
        clone = carousel.items[carousel.items.length - i].cloneNode(true);
        clone.classList.add("bclone");
        carousel.stripe.insertBefore(clone, carousel.items[0]);
        clone = carousel.items[j].cloneNode(true);
        clone.classList.add("aclone");
        carousel.stripe.appendChild(clone);
        j++;
      }
      carousel.itemList = carousel.items;
      carousel.items = element.querySelectorAll(".carousel-item");
      carousel.items.forEach((item, i) => {
        item.addEventListener("click", function (e) {
          settings.click(item, e);
        });
      });
    },
    createDots: function (thumbs) {
      carousel.items.forEach((item, i) => {
        item.setAttribute("data-id", "item-" + i);
        let btn = document.createElement("button");
        btn.className = "dot";
        if (thumbs) {
          let src = item.getAttribute("data-thumb");
          if (src) {
            let thumbImg = document.createElement("img");
            thumbImg.setAttribute("src", src);
            btn.appendChild(thumbImg);
          }
        } else {
          btn.appendChild(document.createTextNode(i + 1));
        }
        btn.addEventListener("click", () => {
          carousel.setActive(i + settings.clones);
        });
        carousel.controls.dots.appendChild(btn);
      });
      carousel.controls.dotitems =
        carousel.controls.dots.querySelectorAll("button");
    },
    highlightDots: function (index) {
      if (!carousel.controls.dotitems) {
        return;
      }
      carousel.controls.dotitems.forEach((dot, i) => {
        dot.classList.remove("active");
      });
      let dotindex = index - settings.clones;
      if (dotindex < 0) dotindex = carousel.controls.dotitems.length - 1;
      if (dotindex >= carousel.controls.dotitems.length) dotindex = 0;
      carousel.controls.dotitems[dotindex].classList.add("active");
    },
    showButtons: function (index) {
      carousel.controls.prev.hidden = false;
      carousel.controls.next.hidden = false;
      if (index === 0) {
        carousel.controls.prev.hidden = true;
      }
      if (index === carousel.items.length - 1) {
        carousel.controls.next.hidden = true;
      }
    },
    setActive: function (index, tr) {
      carousel.prevIndex = carousel.activeIndex * 1;
      carousel.activeIndex = index;
      carousel.position = 0;
      carousel.highlightDots(index);
      carousel.items.forEach((item, i) => {
        if (i === index) {
          if (typeof settings.swipeStart === "function" && tr !== false) {
            let nextindex = index;
            if (item.classList.contains("aclone")) {
              nextindex = settings.clones;
            }
            if (item.classList.contains("bclone")) {
              nextindex = carousel.items.length - settings.clones - 1;
            }
            settings.swipeStart(nextindex, carousel.prevIndex, carousel);
          }
          item.classList.add("active");
          carousel.active = item;
          carousel.setPosition(tr);
          // if (tr !== false) {
            settings.active(item);
          // }
        } else {
          item.classList.remove("active");
        }
      });
      carousel.showButtons(index);
    },
    setPosition: function (tr) {
      carousel.stripe.style.transition =
        tr === false ? "none" : carousel.transition;
      carousel.position = carousel.active.offsetLeft;
      carousel.stripe.style.transform =
        "translateX(-" + carousel.position + "px)";
    },
    setWidth: function () {
      let w = 0;
      carousel.stripe.style.width = "auto";
      carousel.items.forEach((item, i) => {
        item.style.width = "";
        item.style.width = item.offsetWidth + "px";
        w += item.offsetWidth;
      });
      carousel.stripe.style.width = w + "px";
    },
    changePage: function (val, timing) {
      let nextindex = carousel.activeIndex;
      nextindex += val;
      if (nextindex > carousel.items.length - 1) {
        nextindex = carousel.items.length - 1;
      }
      if (nextindex < 0) {
        nextindex = 0;
      }
      carousel.setActive(nextindex);
      //this.preload(this.active);
      if (!timing) {
        carousel.clearTimer();
      }
    },
    setTimer: function (time) {
      carousel.t = setInterval(() => {
        carousel.changePage(1, true);
      }, time * 1000);
    },
    clearTimer: function () {
      if (carousel.t) {
        clearInterval(carousel.t);
      }
    },
  };

  carousel.stripe.addEventListener("transitionend", () => {
    let previndex = carousel.prevIndex;
    if (carousel.active && carousel.active.classList.contains("aclone")) {
      carousel.setActive(settings.clones, false);
    }
    if (carousel.active && carousel.active.classList.contains("bclone")) {
      carousel.setActive(carousel.items.length - settings.clones - 1, false);
    }
    if (typeof settings.swipeEnd === "function") {
      settings.swipeEnd(carousel.activeIndex, previndex, carousel);
    }
  });
  /*carousel.controls.prev.addEventListener("click", () => {
      carousel.changePage(-1);
    });
    carousel.controls.next.addEventListener("click", () => {
      carousel.changePage(1);
    });*/

  window.addEventListener("resize", () => {
    carousel.setPosition(false);
    carousel.setWidth();
    carousel.changePage(0);
  });

  carousel.init();

  return carousel;
};

export { Carousel };
