import { Scroll } from "./scroll.js";

let globalZ = 100;
let Select = function (elem, items, selectCallback) {
  let container = elem;
  let buttonTitle = container.querySelector(".select-title");
  let buttonTitleText = container.querySelector(".select-title-text");
  let hiddenInput = buttonTitle.querySelector("input");
  let buttonText = buttonTitle.innerText;
  let list = container.querySelector(".select-list");
  let selectedValues = [];

  //container.setAttribute("tabindex", 1);

  let scroll = Scroll([list], false);
  let listContainer = list.querySelector(".cscroll-container");
  update(items);

  document.addEventListener("click", function (e) {
    if (!container.contains(e.target)) close();
  });

  container.addEventListener("click", function (e) {
    var t = e.target;

    if (t.className === "select-title" || t.className === "select-title-text") {
      e.preventDefault();
      toggle();
    }

    if (t.classList.contains("select-item-checkbox")) {
      setSelections();
      if (selectCallback) {
        selectCallback(selectedValues);
      }
    }

    if (t.classList.contains("select-item")) {
      //selectOne(t);
      let c = t.querySelector(".select-item-checkbox");
      c.checked = !c.checked;
      setSelections();
      if (selectCallback) {
        selectCallback(selectedValues);
      }
      //close();
    }
  });

  function setSelections() {
    let text = [];
    selectedValues = [];
    list.querySelectorAll(".select-item").forEach((item) => {
      let c = item.querySelector(".select-item-checkbox");
      let cnt = item.querySelector(".select-item-content");
      if (c && c.checked) {
        text.push(item.textContent);
        selectedValues.push({value:c.value, item: item});
      }
    });
    buttonTitleText.innerHTML = text.length ? text.join(", ") : buttonText;
    hiddenInput.value = text.length ? text.join(", ") : "";
    container.classList[selectedValues.length?"add":"remove"]("selected");
    
  }

  function getSelections() {
    setSelections();
    return selectedValues;
  }

  function selectOne(t) {
    list.querySelectorAll(".select-item").forEach((item) => {
      item.classList.remove("select-selected");
      item.querySelector(".select-item-checkbox").checked = false;
    });
    t.classList.add("select-selected");
    t.querySelector(".select-item-checkbox").checked = true;
  }

  function toggle() {
    if (container.classList.contains("open")) {
      close();
    } else {
      open();
    }
  }

  function open() {
    container.classList.add("open", "focus");
    container.style.zIndex = globalZ++;
  }

  function close() {
    container.classList.remove("open");
    setTimeout(function () {
      container.classList.remove("focus");
      container.style.zIndex = null;
    }, 400);
  }

  function empty() {
    /*while (list.hasChildNodes()) {
      list.removeChild(list.firstChild);
    }*/
    list.querySelectorAll(".select-item").forEach((item) => {
      item.remove();
    });
  }

  function add(itm) {
    let item = document.createElement("div");
    item.classList.add("select-item");
    let content = document.createElement("div");
    content.innerHTML = itm.title;
    content.classList.add("select-item-content");
    let control = document.createElement("div");
    control.classList.add("select-item-control");
    if (itm.data) {
        item.data = itm.data;
    }
    if (itm.value) {
      let chk = document.createElement("input");
      chk.classList.add("select-item-checkbox");
      chk.type = "checkbox";
      chk.value = itm.value;
      control.appendChild(chk);
      let chkc = document.createElement("span");
      chkc.classList.add("select-item-checkbox-check");
      control.appendChild(chkc);
    } else {
      item.classList.add("select-item-group");
    }
    item.appendChild(content);
    item.appendChild(control);
    listContainer.appendChild(item);
  }

  function update(items) {
    empty();
    items.forEach((itm) => {
      add(itm);
    });
  }
  return {
    update: update,
    add: add,
    empty: empty,
    scroll: scroll[0],
    selections: getSelections,
  };
};

export { Select };
