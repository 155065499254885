let Scroll = function (collection, cnt) {
  var ret = [];
  collection.forEach(function (parent) {
    if (parent.classList.contains("cscroll-on")) {
      return parent.querySelector(".cscroll-container");
    }

    let bar = document.createElement("div");
    bar.classList.add("cscroll-bar");
    let scroll = document.createElement("div");
    scroll.classList.add("cscroll-scroll");
    let pane = document.createElement("div");
    pane.classList.add("cscroll-scroll-pane");
    let autoPane = document.createElement("span");
    autoPane.classList.add("autoPaneHeight");

    pane.appendChild(autoPane);
    scroll.appendChild(pane);
    bar.appendChild(scroll);

    
    let content = cnt ? cnt : parent.innerHTML;
    let container = document.createElement("div");
    container.classList.add("cscroll-container");
    //container.style.height = parent.offsetHeight + "px";
    container.innerHTML = content;

    let fixedPaneHeight = false,
      trackHeight = 0,
      listHeight = 0,
      paneHeight = 0,
      scrollMove = 0,
      paneMove = 0,
      percentY = 0,
      grabY = 0,
      moveY = 0,
      prevY = 0;

    parent.classList.add("cscroll", "cscroll-on");
    parent.innerHTML = "";
    parent.appendChild(container);
    parent.append(bar);
    let childs = [...container.children];

    
    function calcSizes() {
      fixedPaneHeight = autoPane.offsetParent === null;
      trackHeight = container.offsetHeight;
      listHeight = 0;
      
      childs.forEach(function (acc) {
        listHeight += acc.offsetHeight;
      });
      
      paneHeight = fixedPaneHeight
        ? pane.offsetWidth
        : (trackHeight / listHeight) * trackHeight;
      scrollMove = listHeight - trackHeight;
      paneMove = trackHeight - paneHeight;
      percentY = scrollMove / paneMove;
    }

    bar.addEventListener("click", function (e) {
      var barRect = bar.getClientRects()[0];
      container.scrollTop =
        (e.clientY - barRect.top - paneHeight / 2) * percentY;
      e.preventDefault();
    });

    function handleScrollMove(e) {
      if (grabY === 0) return;
      calcSizes();
      var y = e.touches ? e.touches[0].clientY - grabY : e.clientY - grabY;
      moveY = y - prevY;
      prevY = y;
      container.scrollTop = Math.round(container.scrollTop + moveY * percentY);
    }
    function handleScrollDown(e) {
      var paneRect = pane.getClientRects()[0];
      grabY = e.touches ? e.touches[0].clientY - paneRect.top : e.offsetY;
      prevY = paneRect.top;
      e.preventDefault();
    }

    function handleScrollUp() {
      grabY = 0;
    }

    function handleScroll() {
      container.style.height = null;
      container.style.height = parent.offsetHeight + "px";
      //container.style.maxHeight = "50vh";
      calcSizes();
      parent.classList.remove("cscroll-show");
      if (scrollMove > 0) {
        parent.classList.add("cscroll-show");
      }

      scroll.style.top = paneHeight / 2 + "px";
      scroll.style.height = paneMove + "px";

      pane.style.height = paneHeight + "px";
      pane.style.top =
        Math.round((container.scrollTop / scrollMove) * 100) + "%";
    }

    document.body.addEventListener("mousemove", handleScrollMove);
    document.body.addEventListener("touchmove", handleScrollMove);

    document.body.addEventListener("mouseup", handleScrollUp);
    document.body.addEventListener("touchend", handleScrollUp);

    pane.addEventListener("mousedown", handleScrollDown);
    pane.addEventListener("touchstart", handleScrollDown);

    container.addEventListener("scroll", handleScroll);

    handleScroll();

    window.addEventListener("resize", handleScroll);

    ret.push({
      elem: parent,
      container: container,
      update: handleScroll,
    });
  });
  return ret;
};

export { Scroll };
