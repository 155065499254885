let Modal = function () {
  let body = document.body;
  let html = document.querySelector("html");
  let m = {
    overlay: document.querySelector(".modal-overlay"),
    layer: document.querySelector(".modal-layer"),
    title: document.querySelector(".modal-layer-title"),
    content: document.querySelector(".modal-layer-content"),
    buttons: document.querySelector(".modal-layer-buttons"),
    button: document.querySelector(".modal-layer-button"),
    x: document.querySelector(".modal-layer-close"),
    defaults: {
      title: "Figyelem!",
      content: false,
      buttons: [],
      timer: false,
      modal: true,
      sideclick: false,
      class: 'default'
    },
    scrollY: 0,
    open: function (options) {
      m.settings = Object.assign({}, m.defaults, options);
      /*console.log(m.defaults)
      console.log(options)
      console.log(m.settings)*/
      m.scrollY = window.scrollY;
      m.title.innerHTML = m.settings.title;
      if (m.settings.content) {
        m.content.innerHTML = m.settings.content;
        m.content.classList.remove('hidden');
      }
      else {
        m.content.classList.add('hidden');
      }
      //if (m.settings.buttons) {
      m.createButtons();
      m.layer.classList.remove("error", "default", "success");
      m.layer.classList.add(m.settings.class);
      //}
      if (m.settings.timer) {
        setTimeout(function () {
          m.close();
          if (m.settings.timeEnd) {
            m.settings.timeEnd.call();
          }
        }, m.settings.timer);
      }
      if (m.settings.modal) {
        body.style.position = "fixed";
        body.style.top = -m.scrollY + "px";
      }
      m.overlay.classList.add("show");
    },
    close: function () {
      if (m.settings.modal) {
        body.style.position = "";
        body.style.top = "";
        html.classList.add("nosmooth");
        window.scrollTo(0, m.scrollY);
        html.classList.remove("nosmooth");
      }
      m.overlay.classList.remove("show");
    },
    createButtons: function () {
      /*while (m.buttons.firstChild) {
        m.buttons.firstChild.remove();
      }*/
      m.buttons.replaceChildren();
      m.settings.buttons.forEach((btn) => {
        let btnclone = m.button.cloneNode(true);
        btnclone.innerHTML = btn.text;
        if (btn.class) {
          btnclone.classList.add(btn.class);
        }
        btnclone.addEventListener(
          "click",
          function () {
            btn.action(m);
            m.close();
          },
          false
        );
        m.buttons.appendChild(btnclone);
      });
    },
  };
  //m.button.remove();

  m.x.addEventListener(
    "click",
    function () {
      m.close();
    },
    false
  );

  m.overlay.addEventListener("click", function () {
    if (m.settings.sideclick) {
      if (typeof m.settings.sideclick === "function") {
        m.settings.sideclick();
      }
      m.close();
    }
  });

  return {
    open: m.open,
    close: m.close,
  };
};

export { Modal };
