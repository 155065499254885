let Swipe = function (swipeitem, item, options) {
  var press = false,
    stopClick = false,
    prevX = false,
    moveX = 0,
    posX = 0,
    scrolling = false,
    lengthX = 0,
    originalX = 0,
    originalTransform = "none",
    transition = "none",
    transform = item.style.transform,
    defaults = {
      desktopSwipe: false,
      swipePercent: 33,
      eventStop: false,
    },
    moveTo = function (x) {
      if (x === 0) {
        item.style.transform = originalTransform;
        return;
      }

      if (x === -1) {
        if (typeof settings.prev === "function") {
          settings.prev(transform, originalTransform, originalX);
        }
        return;
      }

      if (x === 1) {
        if (typeof settings.next === "function") {
          settings.next(transform, originalTransform, originalX);
        }
        return;
      }

      let tx = -Math.round(x * swipeitem.offsetWidth);
      if (
        (!settings.next && tx >= 0) ||
        (!settings.prev && tx <= 0) ||
        (settings.next && tx < 0) ||
        (settings.prev && tx > 0)
      ) {
        item.style.transform = "translateX(" + (originalX + tx) + "px)";
      }
    },
    swipeStart = function (e) {
      transition = item.style.transition;
      item.style.transition = "none";
      prevX = false;
      press = true;
      posX = 0;
      lengthX = 0;
      originalTransform = item.style.transform;
      originalX = originalTransform.replace(/[^\-^\d.]/g, "") * 1;
      if (settings.eventStop) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    swipe = function (e) {
      stopClick = false;
      if (!press) {
        return;
      }
      var x = 0;
      if (e.touches) {
        x = e.touches[0].clientX;
      } else {
        x = e.layerX;
      }
      if (prevX) {
        moveX = x - prevX;
      }
      lengthX += Math.abs(moveX);
      if (lengthX > 6 && scrolling === false) {
        posX -= moveX / swipeitem.offsetWidth;
        moveTo(posX);
        e.preventDefault();
      }
      prevX = x;
    },
    swipeEnd = function (e) {
      if (!press) {
        return;
      }
      item.style.transition = transition;
      let limit = settings.swipePercent / 100;
      let d = Math.abs(posX % 1);
      if (moveX < 0 && d > limit) {
        posX = Math.ceil(posX);
      } else {
        if (moveX > 0 && d > limit) {
          posX = Math.floor(posX);
        } else {
          posX = Math.round(posX);
        }
      }
      if (posX > 1) posX = 1;
      if (posX < -1) posX = -1;
      moveTo(posX);
      press = false;
      if (settings.eventStop || (lengthX > 6 && scrolling === false)) {
        //console.log("STOP", e.target);
        e.preventDefault();
        e.stopPropagation();
        //e.stopImmediatePropagation();
        stopClick = true;
      }
    },
    swipeScroll = function (e) {
      clearTimeout(scrolling);
      scrolling = setTimeout(function () {
        scrolling = false;
      }, 80);
    };

  let settings = Object.assign({},defaults, options);

  if ("ontouchstart" in window) {
    window.addEventListener("scroll", swipeScroll, { passive: true });
    swipeitem.addEventListener("touchstart", swipeStart);
    swipeitem.addEventListener("touchend", swipeEnd);
    swipeitem.addEventListener("touchmove", swipe);
  }
  if (settings.desktopSwipe) {
    swipeitem.addEventListener("mousedown", swipeStart, true);
    swipeitem.addEventListener("mouseup", swipeEnd, true);
    swipeitem.addEventListener("mouseout", swipeEnd);
    swipeitem.addEventListener("mousemove", swipe);
    /*swipeitem.querySelectorAll("img").forEach((img) => {
      img.setAttribute("draggable", false);
    });*/
    swipeitem.querySelectorAll("a").forEach(function (link) {
      link.addEventListener(
        "click",
        function (e) {
          if (stopClick) {
            e.preventDefault();
            e.stopPropagation();
          }
        },
        true
      );
    });
  }
  swipeitem.addEventListener("contextmenu", function (e) {
    e.preventDefault();
    swipeEnd(e);
  });
  //swipeitem.addEventListener("blur", swipeEnd);
};

export { Swipe };
