let Validate = function (form, options) {
  let defaults = {
    row: "form-row",
    error: "form-error",
    container: "form-control",
    fields: Array.from(form.elements),
    clear: true,
    submit: function () {},
    messages: {},
  };
  let settings = Object.assign({}, defaults, options);

  function getParent(el, cls) {
    while ((el = el.parentElement) && !el.classList.contains(cls));
    return el;
  }

  function scrollToError() {
    let firstInvalid = form.querySelector("input:invalid");
    if (firstInvalid) {
      let rect = firstInvalid.getBoundingClientRect();
      let top = window.scrollY + rect.top - rect.height - window.innerHeight/3;
      window.scrollTo({
        top: top,
        left: 0,
        behavior: "smooth",
      });
    }
  }

  form.setAttribute("novalidate", "");

  form.addEventListener("submit", function (e) {
    if (!form.checkValidity()) {
      e.preventDefault();
    } else {
      settings.submit(e);
    }
    scrollToError();
  });

  settings.fields.forEach((field) => {
    if (field.type === "submit" || field.type === "hidden") return;
    field.setAttribute("aria-invalid", false);
    let formRow = getParent(field, settings.row);
    let errorField = formRow.querySelector("." + settings.error);
    let errorId = field.id + "Error";
    errorField.setAttribute("id", errorId);
    field.setAttribute("aria-describedBy", errorId);
    field.valid = function () {
      formRow.classList.remove("has-error");
      field.setAttribute("aria-invalid", false);
      errorField.textContent = "";
    };

    field.addEventListener("invalid", () => {
      let message = getMessage(field);
      formRow.classList.add("has-error");
      field.setAttribute("aria-invalid", true);
      errorField.textContent = message || field.validationMessage;
    });

    //field.addEventListener("valid", field.valid);

    field.addEventListener("blur", () => {
      field.checkValidity();
    });

    field.addEventListener("input", () => {
      if (field.checkValidity()) {
        field.valid();
      }
    });
  });

  function getMessage(field) {
    let validity = field.validity;
    let msg = "";
    Object.keys(settings.messages).forEach(function (error) {
      if (validity[error]) {
        msg += settings.messages[error];
      }
    });
    return msg;
  }
};

export { Validate };
